import { render, staticRenderFns } from "./SegmentColumn.vue?vue&type=template&id=04c293ce&scoped=true&"
import script from "./SegmentColumn.vue?vue&type=script&lang=js&"
export * from "./SegmentColumn.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04c293ce",
  null
  
)

export default component.exports